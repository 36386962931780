import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {addAssignment, completeTimesheetCode} from '../../../../actions/assignmentActions';
import {
    addEntry,
    completeTimesheet,
    deleteEntry,
    exportMonthlyTimesheet,
    getTimesheet,
    updateEntry
} from '../../../../actions/timesheetActions';
import {changeUser} from '../../../../actions/userActions';
import {clearTsCodes} from '../../../../actions/tsCodeActions';
import {closeHeaderScreens} from '../../../../actions/callsActions';
import {updateSetting} from '../../../../actions/settingsActions';
import DateHelper from '../../../../helpers/dateHelper';
import ExportMonthlyDetail from './ExportMonthlyDetail';
import React from 'react';
import SettingsDetail from '../settings/SettingsDetail';
import TimesheetCompleteDetail from './TimesheetCompleteDetail';
import TimesheetDays from './TimesheetDays';
import TimesheetDetail from './TimesheetDetail';
import TimesheetFooter from './TimesheetFooter';
import TimesheetNavigation from './TimesheetNavigation';
import TimesheetWeek from './TimesheetWeek';

import {ActiveDayInfo, DetailViewInfo} from '../../../../typescript/infoTypes';

import {MonthlyTimesheetToExport} from '../../../../typescript/timesheetTypes';
import { Period } from '../../../../typescript/dateTypes';
import { PermissionTypes } from '../../../../types/permissionTypes';
import {
    StoreEmployee,
    StoreState, StoreSupplier,
    StoreTimesheetCode, StoreTimesheetEntry,
    StoreTsCode
} from '../../../../typescript/storeTypes';
import AccessibleUserHelper from '../../../../helpers/accessibleUserHelper';
import ChangeUserDetail from '../../header/profile/ChangeUserDetail';
import DeserifyHelper from '../../../../helpers/DeserifyHelper';
import MessageHelper from '../../../../helpers/messageHelper';
import PermissionHelper from '../../../../helpers/permissionHelper';
import UserHelper from '../../../../helpers/userHelper';

export class Timesheet extends React.Component<Props, State> {
    state: State = {
        activeDay: {
            dayIndex: this.props.timesheet.period.days.findIndex(day => DateHelper.isToday(day)),
            detailView: undefined
        },
        canAddEntry: false,
        canUpdateEntry: false,
        canDeleteEntry: false,
        canAddTimesheetCode: false,
        canDeleteTimesheetCode: false,
        period: this.props.timesheet.period
    };

    onNavigateDay = (dayIndex: number): void => {
        if (this.state.activeDay.dayIndex !== dayIndex) {
            this.setState({activeDay: {dayIndex}});
        }
    };

    onDetailView = (entry: StoreTimesheetEntry, timesheetCode?: StoreTimesheetCode): void => {
        const activeDay: ActiveDayInfo = Object.assign({}, this.state.activeDay);
        activeDay.detailView = {entry, timesheetCode};
        this.setState({activeDay});
    };

    onCloseDetailView = (): void => {
        this.setState({activeDay: {dayIndex: this.state.activeDay.dayIndex}});
    };

    onEntryChange = (entry: StoreTimesheetEntry, timesheetCode: StoreTimesheetCode): void => {
        const entryInfo = {
            username: this.props.user.impersonatedUser.username,
            tsCodeId: timesheetCode.id,
            workDate: this.props.timesheet.period.days[this.state.activeDay.dayIndex].dateString
        };

        if (entry.id) {
            if (entry.totalHours || entry.comments || !entry.canBeDeleted) {
                this.props.updateEntry(entry, entryInfo, this.props.user);
            } else {
                this.props.deleteEntry(entry, entryInfo, this.props.user);
            }
        }
        else {
            if (entry.totalHours || entry.comments) {
                this.props.addEntry(entry, entryInfo, this.props.user);
            }
        }

        this.onCloseDetailView();
    };

    navigateMonth = (direction: number): void => {
        const newStartDate = DateHelper.addMonths(this.props.timesheet.period.startDate, direction);
        const newEndDate = DateHelper.lastDateOfMonth(newStartDate);

        if (newStartDate.getFullYear() > new Date().getFullYear() + 1) return;

        const newPeriod = DateHelper.getPeriod(newStartDate, newEndDate);

        this.props.getTimesheet(newPeriod, this.props.user.impersonatedUser);
    };

    getPreviousMonth = (): void => {
        this.navigateMonth(-1);
    };

    getNextMonth = (): void => {
        this.navigateMonth(1);
    };

    addAssignment = (tsCode: StoreTsCode) => {
        const assignment = {
            username: this.props.user.impersonatedUser.username,
            tsCodeId: tsCode.id,
            name: tsCode.name,
            description: tsCode.description,
            month: this.props.timesheet.period.month,
            year: this.props.timesheet.period.year,
            comments: '',
            isBillable: tsCode.isBillable
        };

        this.props.addAssignment(assignment, this.props.user);
    };

    filteredTimesheetCodes = () => {
        return this.props.timesheet.timesheetCodes.filter(tsCode => tsCode.entries.length > 0);
    };

    exportMonthlyTimesheet = (hoursPerWeek: number, emailAddress: string,
        ownProjectLeader: string, clientProjectLeader: string,
        selectedSupplier: StoreSupplier, selectedTimesheetCodes: StoreTimesheetCode[], hideComments: boolean) => {

        const monthlyTimesheetToExport: MonthlyTimesheetToExport = {
            month: this.props.timesheet.period.month,
            year: this.props.timesheet.period.year,
            username: this.props.user.impersonatedUser.username,
            hoursPerWeek: hoursPerWeek,
            emailAddress: emailAddress,
            ownProjectLeader: ownProjectLeader,
            clientProjectLeader: clientProjectLeader,
            companyId: selectedSupplier.id,
            hideComments: hideComments,
            tsCodes: selectedTimesheetCodes.map(timesheetCode => timesheetCode.id)
        };

        this.props.exportMonthlyTimesheet(monthlyTimesheetToExport);
    };

    closeHeaderScreens = () => {
        this.props.closeHeaderScreens();
    };

    completeTimesheet = () => {
        this.props.completeTimesheet({
            username: this.props.user.impersonatedUser.username,
            period: this.props.timesheet.period
        }, this.props.user);

        this.closeHeaderScreens();
    };

    completeTimesheetCodes = (timesheetCodes: StoreTimesheetCode[]) => {
        for (const code of timesheetCodes) {
            this.props.completeTimesheetCode({
                username: this.props.user.impersonatedUser.username,
                month: this.props.timesheet.period.month,
                year: this.props.timesheet.period.year,
                tsCodeId: code.id
            }, this.props.user);
        }

        this.closeHeaderScreens();
    };

    changeUser = (user: StoreEmployee) => {
        this.closeHeaderScreens();
        this.setState({activeDay: {
            dayIndex: this.props.timesheet.period.days.findIndex(day => DateHelper.isToday(day))
        }});
        if(this.props.user.impersonatedUser.username === user.username) return;
        this.props.changeUser(user);
    };

    updateSetting = (name: string, value: any): void => {
        this.props.updateSetting(name, value);
    };

    render() {
        return (
            this.props.changeUserOpen ?
                <ChangeUserDetail me={this.props.user.employee}
                                  settings={this.props.settings}
                                  changeUser={this.changeUser}
                                  closeModal={this.closeHeaderScreens}
                                  visibleUsers={this.props.user.visibleUsers} /> :
            this.props.completeTimesheetOpen ?
                <TimesheetCompleteDetail closeModal={this.closeHeaderScreens}
                                         timesheet={this.props.timesheet}
                                         completeTimesheet={this.completeTimesheet}
                                         completeTimesheetCodes={this.completeTimesheetCodes}
                                         calls={this.props.calls}/> :
            this.props.exportMonthlyOpen ?
                <ExportMonthlyDetail closeModal={this.closeHeaderScreens}
                                     timesheetCodes={this.filteredTimesheetCodes()}
                                     suppliers={this.props.suppliers}
                                     exportMonthlyTimesheet={this.exportMonthlyTimesheet}
                                     hoursPerWeek={this.props.settings.defaultContractHours} /> :
            this.props.settingsOpen ?
                <SettingsDetail closeModal={this.closeHeaderScreens}
                          settings={this.props.settings}
                          user={this.props.user}
                          updateSetting={this.updateSetting} /> :
            this.state.activeDay.detailView ?
                <TimesheetDetail period={this.props.timesheet.period}
                                 day={this.props.timesheet.period.days[this.state.activeDay.dayIndex]}
                                 detailView={this.state.activeDay.detailView as unknown as DetailViewInfo}
                                 onCloseDetailView={this.onCloseDetailView}
                                 onEntryChange={this.onEntryChange}
                                 onDetailView={this.onDetailView}

                                 tsCodes={this.props.tsCodes}
                                 isReadOnly={!this.state.canAddEntry || !this.state.canUpdateEntry || !this.state.canDeleteEntry}
                                 canAddTimesheetCode={this.state.canAddTimesheetCode}
                                 canDeleteTimesheetCode={this.state.canDeleteTimesheetCode}
                                 isAccessibleUser={AccessibleUserHelper.isAccessibleUser(this.props.user)}

                                 addAssignment={this.addAssignment}
                                 clearTsCodes={this.props.clearTsCodes} /> :
            <div className="timesheet">
                <TimesheetNavigation user={this.props.user}
                                     timesheet={this.props.timesheet}
                                     getPreviousMonth={this.getPreviousMonth}
                                     getNextMonth={this.getNextMonth}
                                     getTimesheet={this.props.getTimesheet}/>
                <TimesheetWeek/>
                <TimesheetDays timesheet={this.props.timesheet}
                               activeDayIndex={this.state.activeDay.dayIndex}
                               onNavigateDay={this.onNavigateDay}/>
                <TimesheetFooter user={this.props.user}
                                 timesheet={this.props.timesheet}
                                 settings={this.props.settings}
                                 activeDayIndex={this.state.activeDay.dayIndex}
                                 canAddEntry={this.state.canAddEntry}
                                 maintenanceDetails={this.props.maintenanceDetails}

                                 onDetailView={this.onDetailView}/>
            </div>
        );
    }

    questionId: string|number = 0;

    static getDerivedStateFromProps(nextProps: Props, state: State) {
        let newState = {};
        if (nextProps.timesheet.period !== state.period) {
            const dayIndex = nextProps.timesheet.period.days.findIndex(day => DateHelper.isToday(day));
            if (state.activeDay.dayIndex !== dayIndex) {
                newState = Object.assign(newState, {
                    activeDay: {dayIndex},
                    period: nextProps.timesheet.period
                });
            }
        }

        const isImpersonating = UserHelper.isImpersonating(nextProps.user);

        const permissionAddEntry = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ACTUAL_WORK)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ACTUAL_WORK_CURRENT_USER));
        const permissionUpdateEntry = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.PUT_ACTUAL_WORK)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.PUT_ACTUAL_WORK_CURRENT_USER));
        const permissionDeleteEntry = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.DELETE_ACTUAL_WORK)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.DELETE_ACTUAL_WORK_CURRENT_USER));
        const permissionAddTimesheetCode = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ASSIGNMENT)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ASSIGNMENT_CURRENT_USER));
        const permissionDeleteTimesheetCode = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.DELETE_ASSIGNMENT)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.DELETE_ASSIGNMENT_CURRENT_USER));

        return Object.assign(newState, {
            canAddEntry: permissionAddEntry,
            canUpdateEntry: permissionUpdateEntry,
            canDeleteEntry: permissionDeleteEntry,
            canAddTimesheetCode: permissionAddTimesheetCode,
            canDeleteTimesheetCode: permissionDeleteTimesheetCode
        });
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.timesheet !== prevProps.timesheet) {
            if (this.props.user.employee.username !== '' && AccessibleUserHelper.isAccessibleUser(this.props.user) && !DateHelper.isSamePeriod(prevProps.timesheet.period, this.props.timesheet.period) && this.props.timesheet.timesheetCodes.filter(t => !t.isVacationOrIllness && !t.isHoliday).length === 0) {
               this.questionId = MessageHelper.showEmptyTimesheetMessage(this.questionId);
            } else if(this.props.timesheet.timesheetCodes.filter(t => !t.isVacationOrIllness && !t.isHoliday).length > 0) {
                MessageHelper.dismiss(this.questionId);
            }
        }

        if (this.props.user.impersonatedUser.employeeId === 0) return;
        if (this.props.user.impersonatedUser !== prevProps.user.impersonatedUser) {
            this.props.getTimesheet(this.props.timesheet.period, this.props.user.impersonatedUser);
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

type State = {
    activeDay: ActiveDayInfo,
    //permissions
    canAddEntry: boolean,
    canUpdateEntry: boolean,
    canDeleteEntry: boolean,
    canAddTimesheetCode: boolean,
    canDeleteTimesheetCode: boolean,
    //permissions
    period: Period
};

function mapStateToProps(state: StoreState) {
    return {
        timesheet: {...state.timesheet, period: DeserifyHelper.deserify<Period>(state.timesheet.period)},
        user: state.user,
        tsCodes: state.tsCodes,
        completeTimesheetOpen: state.calls.completeTimesheetOpen,
        exportMonthlyOpen: state.calls.exportMonthlyOpen,
        changeUserOpen: state.calls.changeUserOpen,
        settingsOpen: state.calls.settingsOpen,
        suppliers: state.suppliers,
        calls: state.calls,
        settings: state.settings,
        maintenanceDetails: state.maintenanceDetails
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        getTimesheet,
        addEntry,
        updateEntry,
        deleteEntry,
        addAssignment,
        clearTsCodes,
        exportMonthlyTimesheet,
        closeHeaderScreens,
        completeTimesheet,
        completeTimesheetCode,
        changeUser,
        updateSetting
    }, dispatch);
}

export default connector(Timesheet);
